/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import { authorize } from '../modules/middleware';
import { workspaceId, useWorkspaceStore } from '@/store/workspaces';
import { usePlansStore } from '@/store/plans';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/default.vue'),
      children: [
        {
          path: '',
          name: 'accueil',
          component: () => import('@/pages/home/HomeView.vue'),
          meta: {
            title: 'Accueil',
            metaTags: [
              {
                name: 'description',
                content: ''
              },
              {
                property: 'og:description',
                content: ''
              }
            ]
          },
        },
        {
          path: '/pricing',
          name: 'pricing',
          component: () => import('@/pages/pricing/PricingView.vue'),
          meta: {
            title: 'Pricing',
            metaTags: [
              {
                name: 'description',
                content: 'Welcome on the pricing page !'
              },
              {
                property: 'og:description',
                content: 'Welcome on the pricing page !'
              }
            ]
          }
        },
        {
          path: '/legal',
          name: 'legal',
          component: () => import('@/pages/legal/terms.vue'),
          meta: { title: 'Website Terms' }
        },
        {
          path: '/legal/terms',
          name: 'terms',
          component: () => import('@/pages/legal/terms.vue'),
          meta: { title: 'Website Terms' }
        },
        {
          path: '/legal/sales',
          name: 'sales',
          component: () => import('@/pages/legal/sales.vue'),
          meta: { title: 'General Conditions of Sale' }
        },
        {
          path: '/legal/privacy-policy',
          name: 'privacy-policy',
          component: () => import('@/pages/legal/privacy-policy.vue'),
          meta: { title: 'Privacy Policy' }
        },
      ],
    },
    {
      path: '/plans',
      component: () => import('@/layouts/empty.vue'),
      children: [
        {
          path: 'free',
          name: 'free',
          component: () => import('@/pages/home/free.vue'),
          meta: { title: 'Free' },
        },
        {
          path: 'pro',
          name: 'pro',
          component: () => import('@/pages/home/pro.vue'),
          meta: { title: 'Pro' },
        },
        {
          path: 'business',
          name: 'business',
          component: () => import('@/pages/home/business.vue'),
          meta: { title: 'Business' },
        },
      ],
    },
    {
      path: '/solutions',
      component: () => import('@/layouts/empty.vue'),
      children: [
        {
          path: 'collaboration',
          name: 'collaboration',
          component: () => import('@/pages/home/collaboration.vue'),
          meta: { title: 'Collaboration' },
        },
        {
          path: 'filtres',
          name: 'filtres',
          component: () => import('@/pages/home/filtres.vue'),
          meta: { title: 'Filtres' },
        },
        {
          path: 'liaison-de-compte',
          name: 'liaison-de-compte',
          component: () => import('@/pages/home/liaison-de-compte.vue'),
          meta: { title: 'Liaison de compte' },
        },
        {
          path: 'messagerie',
          name: 'messagerie',
          component: () => import('@/pages/home/messagerie.vue'),
          meta: { title: 'Messagerie' },
        },
        {
          path: 'autosecure',
          name: 'autosecure',
          component: () => import('@/pages/home/autosecure.vue'),
          meta: { title: 'Autosecure' },
        },
        {
          path: 'flux',
          name: 'flux',
          component: () => import('@/pages/home/flux.vue'),
          meta: { title: 'Flux' },
        }
      ],
    },
    {
      path: '/auth',
      component: () => import('@/layouts/empty.vue'),
      beforeEnter: checkToken,
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/auth/LoginView.vue'),
          meta: { title: 'Login' },
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/auth/RegisterView.vue'),
          meta: { title: 'Register' }
        },
        {
          path: 'confirm',
          name: 'confirm',
          component: () => import('@/pages/auth/ConfirmView.vue'),
          meta: { title: 'Confirm email' }
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () => import('@/pages/auth/ResetPasswordView.vue'),
          meta: { title: 'Login' },
        },
        {
          path: '',
          name: 'redirect_login',
          redirect: '/auth/login'
        },
      ],
    },
    {
      path: '/dashboard/:workspaceId',
      beforeEnter: checkLogin,
      component: () => import('@/layouts/dashboard/DashboardLayout.vue'),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/views/dashboards/default/DefaultDashboard.vue'),
          meta: { title: 'Le flux' },
          beforeEnter: authorize
        },
        {
          path: 'filtres',
          beforeEnter: authorize,
          children: [
            {
              path: '',
              name: 'filtres',
              component: () => import('@/views/dashboards/filtres/ListFiltres.vue'),
              meta: { title: 'Le flux' },
              beforeEnter: authorize
            },
            {
              path: 'add',
              name: 'ajouter-un-filtre',
              component: () => import('@/views/dashboards/filtres/FiltreAdd.vue'),
              meta: { title: 'Ajouter un filtre' },
              beforeEnter: authorize
            },
          ]
        },
        {
          path: 'business',
          name: 'business',
          component: () => import('@/pages/home/business.vue'),
          meta: { title: 'Business' },
          beforeEnter: authorize
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/pages/404/404.vue'),
    },
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

import loadingStore from '../store/loading';
import { Requester } from '../modules/requester';
import { watch } from 'vue';
import { useUserStore } from '@/store/users';

interface VerifyResponse { 
  status: number
  data: {
    verified: boolean,
    token: string
  }
}
const checkLoginRequester = new Requester('/users/check-token', false)

interface MetaTag {
  name: string;
  content: string
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
  loadingStore.startLoading();
  // IMPORTANT REFERENCEMENT
  document.title = to.meta.title ? `Takefast | ${to.meta.title}` : 'Takefast'; // titre de la page
  const head = document.getElementsByTagName('head')[0]; // reset de la meta description
  const existingTags = head.querySelectorAll('meta[name="description"], meta[property^="og:"]'); 
  existingTags.forEach(tag => {
    head.removeChild(tag);
  });

  if (Array.isArray(to.meta.metaTags)) {
    const metaTags = to.meta.metaTags as MetaTag[];
    metaTags.forEach(tag => {
      const element = document.createElement('meta');
      element.setAttribute('name', tag.name);
      element.setAttribute('content', tag.content);
      document.getElementsByTagName('head')[0].appendChild(element);
    });
  }

  next();
});

router.afterEach(() => {
  loadingStore.stopLoading();
});

async function checkLogin(to: RouteLocationNormalized) {
  if (localStorage.getItem('userId') === null ||
    localStorage.getItem('token') === null) {
      if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
      return { name: 'login'};
    }

  const data: VerifyResponse = await checkLoginRequester.post({
    userId: localStorage.getItem('userId')
  });

  if (data.status === 400 || data.status === 401 || data.status === 404) { 
    localStorage.clear();
    if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
    return { name: 'login'};
  }

  localStorage.setItem('token', data.data.token);

  if (!data.data.verified) {
    const userStore = useUserStore();
    await userStore.sendCode();
    if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
    return { name: 'confirm' };
  }
}

async function checkToken(to: RouteLocationNormalized) {

  if (localStorage.getItem('userId') != null) {
    const data: VerifyResponse = await checkLoginRequester.post({
        userId: localStorage.getItem('userId')
    });
    localStorage.setItem('token', data.data.token);
    
    if (data.data.verified && to.name !== 'join') {
      const workspaceStore = useWorkspaceStore();
      const planStore = usePlansStore();
      await workspaceStore.getWorkspaceList();
      await planStore.getAllPlans();
      //récupérer le workspaceId pour le passer en params
      console.log(workspaceId.value);
      if (workspaceId.value) return { name: 'dashboard', params: { workspaceId: workspaceId.value.toString() } };
      return { name: 'no-workspace' };
    }
  }
}

watch(workspaceId, () => {
  if (!workspaceId.value || !router.currentRoute.value.fullPath.includes('/dashboard')) return
  authorize(router.currentRoute.value, router.currentRoute.value, router.replace);
})
export default router
