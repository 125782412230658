// Utilities
import { defineStore } from 'pinia'
import { ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  const success_snack = ref(false);
  const error_snack = ref(false);
  
  const snack_text = ref('');

  const rules = {
      username: [
          (v: string) => !!v || 'Le nom d\'utilisateur est requis',
          (v: string) => v.length >= 3 || 'Le nom d\'utilisateur doit comporter au moins 3 caractères',
          (v: string) => v.length <= 24 || 'Le nom d\'utilisateur doit comporter 24 caractères ou moins',
          (v: string) => /^[a-zA-Z0-9-_]+(?: [a-zA-Z0-9-_]+)*$/.test(v) || 
              'Le nom d\'utilisateur doit contenir uniquement des lettres, chiffres, tirets, underscores et espaces (mais pas au début ou à la fin)'
      ],
      email: [
          (v: string) => !!v || 'L\'adresse e-mail est requise',
          (v: string) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'L\'adresse e-mail doit être valide'
      ],
      password: [
          (v: string) => !!v || 'Le mot de passe est requis',
          (v: string) => v.length >= 12 || 'Le mot de passe doit comporter au moins 12 caractères',
          (v: string) => v.length <= 32 || 'Le mot de passe doit comporter 32 caractères ou moins',
          (v: string) => /[A-Z]/.test(v) || 'Le mot de passe doit contenir au moins une lettre majuscule',
          (v: string) => /[a-z]/.test(v) || 'Le mot de passe doit contenir au moins une lettre minuscule',
          (v: string) => /[0-9]/.test(v) || 'Le mot de passe doit contenir au moins un chiffre',
          (v: string) => /[\W_]/.test(v) || 'Le mot de passe doit contenir au moins un caractère spécial',
          (v: string) => !/\s/.test(v) || 'Le mot de passe ne peut pas contenir d\'espaces',
      ],
      fileInput: [
          (value: File[]) => {
              if (value.length === 0) {
                  return 'Veuillez sélectionner un fichier image.';
              }
              const file = value[0];
              const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
              if (!allowedTypes.includes(file.type)) {
                  return 'Seules les images PNG, JPEG et WEBP sont autorisées.';
              }
              return true;
          }
      ]
  };


  function validateEmail(email: string): boolean {
    if (!email) {
        return false;
    }
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  }


  function validatePassword(password: string): boolean {
    if (!password) {
        return false;
    }
    if (password.length < 12 || password.length > 32) {
        return false;
    }
    if (!/[A-Z]/.test(password)) {
        return false;
    }
    if (!/[a-z]/.test(password)) {
        return false;
    }
    if (!/[0-9]/.test(password)) {
        return false;
    }
    if (!/[\W_]/.test(password)) {
        return false;
    }
    if (/\s/.test(password)) {
        return false;
    }
    return true;
}


  const countries: string[] = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City (Holy See)",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  return { success_snack, error_snack, snack_text, rules, countries, validatePassword, validateEmail };
});
