import loadingStore from '../store/loading';
import { RouteLocationNormalized } from 'vue-router';
import { useWorkspaceStore, actualWorkspace, workspaceId } from '@/store/workspaces';
import { ref } from 'vue';

export const authoriseRunning = ref(false);
export const pageBeforeDisable = ref('dashboard');

export async function authorize(to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) {
  loadingStore.startLoading();
  authoriseRunning.value = true;

  const workspaceStore = useWorkspaceStore();

  if (!actualWorkspace.value || from.name === 'login') {
    await workspaceStore.getWorkspaceList().catch((error) => {
      console.error('Error fetching user permissions:', error);
      authoriseRunning.value = false;
      loadingStore.stopLoading();
      return next({ name: 'dashboard', params: { workspaceId: workspaceId.value || workspaceStore.workspacesList[0]['_id'] } });
    });
  }

  authoriseRunning.value = false;
  loadingStore.stopLoading();
  return next();
}
